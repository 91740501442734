import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_PARADISE } from "src/constants/minutApi"
import {
  paradiseFirmwaresKeys,
  useParadiseFirmwareCache,
} from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueryCache"
import {
  IParadiseFirmwareResponse,
  IParadiseFirmwaresListFilter,
  IParadiseFirmwaresListResponse,
  IParadiseUpdateFirmwareTagsBody,
  IParadiseUpdateFirmwareTagsResponse,
} from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

// ts-prune-ignore-next
export function useFetchParadiseFirmwares(props?: {
  filter?: IParadiseFirmwaresListFilter
  options?: UseQueryOptions<
    IParadiseFirmwaresListResponse,
    AxiosError,
    IParadiseFirmwaresListResponse,
    ReturnType<typeof paradiseFirmwaresKeys.firmwareList>
  >
}) {
  async function fetchParadiseFirmwares() {
    const response = await minutApiHttpClient.get(`${API_PARADISE}/firmwares`, {
      params: props?.filter && {
        "filter[hardware_version]": props.filter.hardware_version,
      },
    })

    return response.data
  }

  return useQuery({
    queryKey: paradiseFirmwaresKeys.firmwareList(props?.filter),
    queryFn: fetchParadiseFirmwares,
    ...props?.options,
  })
}

// ts-prune-ignore-next
export function useFetchParadiseFirmware({
  fwNumber,
  options,
}: {
  fwNumber: number
  options?: UseQueryOptions<
    IParadiseFirmwareResponse,
    AxiosError,
    IParadiseFirmwareResponse,
    ReturnType<typeof paradiseFirmwaresKeys.firmware>
  >
}) {
  async function fetchParadiseFirmware() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/firmwares/${fwNumber}`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseFirmwaresKeys.firmware(fwNumber),
    queryFn: fetchParadiseFirmware,
    ...options,
  })
}

// ts-prune-ignore-next
export function usePutParadiseFirmwareTags() {
  const paradiseFirmwareCache = useParadiseFirmwareCache()

  async function putParadiseFirmwareTags({
    fwNumber,
    body,
  }: {
    fwNumber: number
    body: IParadiseUpdateFirmwareTagsBody
  }) {
    const response =
      await minutApiHttpClient.put<IParadiseUpdateFirmwareTagsResponse>(
        `${API_PARADISE}/firmwares/${fwNumber}/tags`,
        body
      )

    return response.data
  }

  return useMutation({
    mutationFn: putParadiseFirmwareTags,
    onSuccess: (data, vars) => {
      return Promise.all([
        paradiseFirmwareCache.invalidateParadiseFirmwares(),
        paradiseFirmwareCache.invalidateParadiseFirmware(vars.fwNumber),
      ])
    },
  })
}
