import { useQueryClient } from "@tanstack/react-query"

import { IParadiseFirmwaresListFilter } from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"

export const paradiseFirmwaresKeys = {
  all() {
    return ["paradiseFirmware"] as const
  },

  firmwareLists() {
    return [...this.all(), "list"] as const
  },

  firmwareList(filter?: IParadiseFirmwaresListFilter) {
    if (!filter) {
      return [...this.firmwareLists()] as const
    }

    return [...this.firmwareLists(), filter] as const
  },

  firmware(fwNumber: number) {
    return [...this.all(), fwNumber] as const
  },
} as const

export function useParadiseFirmwareCache() {
  const queryClient = useQueryClient()

  async function invalidateParadiseFirmware(fwNumber: number) {
    const cacheKey = paradiseFirmwaresKeys.firmware(fwNumber)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  async function invalidateParadiseFirmwares(
    filter?: IParadiseFirmwaresListFilter
  ) {
    const cacheKey = paradiseFirmwaresKeys.firmwareList(filter)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  return {
    invalidateParadiseFirmware,
    invalidateParadiseFirmwares,
  }
}
